import React from 'react';
import { asset } from '../../helpers/dom.helper';
import './whyLussoPackHome.scss'
import { Media, MediaContextProvider } from "../../media";

const WhyLussopackHome = React.memo(() => {


  return (
    <MediaContextProvider>
        <Media greaterThanOrEqual="md">
              <div id="whyLussopack">
                <div className="feature-platform">
                  <div className="topContainer">
                      <div className="col-8 text-center">
                        <h2 className="text-header">Why Lussopack?</h2>
                        <hr className='line'/>            
                      </div>
                    <div className='reasonWhyLussopack'>
                      <div className='reasonContainer'>
                        <img 
                          alt="customization"
                          src={asset('/images/home/whylussopack/customizationImage.webp')}  
                          width="64px"
                          height="90px"
                        />
                        <p>Customization<br />for your desire<br />budget</p>
                      </div>
                      <div className='reasonContainer'>
                        <img 
                            alt="specialDesignImage"
                          src={asset('/images/home/whylussopack/specialDesignImage.webp')}
                          width="64px"
                          height="64px"  
                        />
                        <p>Special Design by<br />Professional<br /> Team</p>
                      </div>
                      <div className='reasonContainer'>
                        <img 
                          alt="wolrdClassQuality"
                          src={asset('/images/home/whylussopack/wolrdClassQualityImage.webp')}  
                          width="64px"
                          height="64px"
                        />
                        <p>Wolrd Class<br />Quality</p>
                      </div>
                      <div className='reasonContainer'>
                        <img 
                          alt="delivery"
                          src={asset('/images/home/whylussopack/deliveryImage.webp')}  
                          width="111px"
                          height="64px"
                        />
                        <p>Quick<br />Production<br />& Delivery</p>
                      </div>
                    </div>
                  </div>
                  <div className="botContainer">
                    <div className='textContainer'>
                      <h2>Sustainability is at our core</h2>
                      <p>We integrate sustainable practices into every aspect of our<br />operations, from material sourcing to production<br /> processes, setting a new standard for responsible and<br /> luxurious packaging as a custom jewelry packaging manufacturer</p>
                    </div>
                    <div className='imageContainer'>
                      <img 
                        src={asset('/images/home/whylussopack/sustainabilityImage.webp')}
                        alt="sustainability"
                        width="720"
                        height="auto"
                      />
                    </div>
                  </div>
                </div>
              </div>
        </Media>
        <Media lessThan="md">
              <div id="whyLussopackMobile">
                <div className="feature-platform">
                  <div className="topContainer">
                      <div className="text-center">
                        <h2 className="text-header">Why Lussopack?</h2>
                        <hr className='line'/>            
                      </div>
                    <div className='reasonWhyLussopack'>
                      <div className='reasonContainer'>
                        <img 
                          alt="customization"
                          src={asset('/images/home/whylussopack/customizationImage.webp')} 
                          width="50"
                          height="73" 
                        />
                        <p>Customization for your<br /> desire budget</p>
                      </div>
                      <div className='reasonContainer'>
                        <img 
                            alt="specialDesignImage"
                          src={asset('/images/home/whylussopack/specialDesignImage.webp')}  
                          width="51"
                          height="51" 
                        />
                        <p>Special Design by<br />Professional Team</p>
                      </div>
                      <div className='reasonContainer'>
                        <img 
                          alt="wolrdClassQuality"
                          src={asset('/images/home/whylussopack/wolrdClassQualityImage.webp')}  
                          width="51"
                          height="51" 
                        />
                        <p>Wolrd Class Quality</p>
                      </div>
                      <div className='reasonContainer'>
                        <img 
                          alt="delivery"
                          src={asset('/images/home/whylussopack/deliveryImage.webp')}  
                          width="87"
                          height="51" 
                        />
                        <p>Quick Production<br />& Delivery</p>
                      </div>
                    </div>
                  </div>

                  <div className="botContainer">
                    <div className='imageContainer'>
                      <img 
                        src={asset('/images/home/whylussopack/sustainabilityImage.webp')}
                        alt="sustainability"
                        width="auto"
                        height="240px"
                      />
                    </div>
                    <div className='textContainer'>
                      <h2>Sustainability is at our core</h2>
                      <p>We integrate sustainable practices into every aspect of our<br />operations, from material sourcing to production<br />processes, setting a new standard for responsible and<br />luxurious packaging as a custom jewelry packaging manufacturer</p>
                    </div>
                  </div>
                </div>
              </div>
        </Media>
    </MediaContextProvider>
  )

})

export default WhyLussopackHome
